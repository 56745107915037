import './App.css';
import Header from './Header';
import Biz from './Biz';
import lupa from './lupa.svg'
import it from './it.svg'
import it1 from './it1.svg'
import it2 from './it2.svg'
import it3 from './it3.svg'
import it4 from './it4.svg'
import play from './play.svg'
import meta from './meta.svg'

function App() {
  return (
    <div >
      <header className='header'>
        <Header/>
      </header>
      {/* Malumot */}
      <div className='malumotlar'>
<p className='asosiy'>Treninglar mashqlar</p>
      </div>
      <button className='qidr'>Qidirish <img className='lupa' src={lupa} alt="" /></button>
      <select className='filtr'>
        <option>Filter</option>
      </select>
   {/* asida */}
 <aside>
  <div><img className='it' src={it} alt="" />
  <img className='play' src={play} alt="" />
  <a className='tanl' href="#">Kasp tanlash sirlari</a>
  <img className='meta' src={meta} alt="" />
  </div>
  <div><img className='it' src={it1} alt="" />
  <img className='play' src={play} alt="" />
  <a className='tanl' href="#">IT mutaxassisi bolish haqida</a>
  <img className='meta' src={meta} alt="" />
  </div>
  <div><img className='it' src={it2} alt="" />
  <img className='play' src={play} alt="" />
  <a className='tanl' href="#">IT mutaxassisi bolish haqida</a>
  <img className='meta' src={meta} alt="" />
  </div>
  <div><img className='it' src={it3} alt="" />
  <img className='play' src={play} alt="" />
  <a className='tanl' href="#">Bola psixologiyasi haqida</a>
  <img className='meta' src={meta} alt="" />
  </div>
  <div><img className='it' src={it4} alt="" />
  <img className='play' src={play} alt="" />
  <a className='tanl' href="#">Rasm chizish san’ati haqida</a>
  <img className='meta' src={meta} alt="" />
  </div>
  <div><img className='it' src={it} alt="" />
  <img className='play' src={play} alt="" />
  <a className='tanl' href="#">IT mutaxassisi bolish haqida</a>
  <img className='meta' src={meta} alt="" />
  </div>
  <div><img className='it' src={it} alt="" />
  <img className='play' src={play} alt="" />
  <a className='tanl' href="#">IT mutaxassisi bolish haqida</a>
  <img className='meta' src={meta} alt="" />
  </div>
  <div><img className='it' src={it} alt="" />
  <img className='play' src={play} alt="" />
  <a className='tanl' href="#">IT mutaxassisi bolish haqida</a>
  <img className='meta' src={meta} alt="" />
  </div>
  <div><img className='it' src={it} alt="" />
  <img className='play' src={play} alt="" />
  <a className='tanl' href="#">IT mutaxassisi bolish haqida</a>
  <img className='meta' src={meta} alt="" />
  </div>

 </aside>
{/* Bizning joyi */}
 <Biz />

    </div>
  );
}

export default App;
