import { Component } from "react";
class Header extends Component{
  render(){
    return <div> <p className='prof'>Prof<span>.uz</span></p>
    <ol className='ol'>
      <li><a className='a' href="#">Kasp tanlash</a></li>
      <li><a className='a' href="#">Rezume yarat</a></li>
      <li><a className='a' href="#">Treninglar</a></li>
      <li><a className='a' href="#">Broshyura</a></li>
      <li><a className='a' href="#">Biz haqimizda</a></li>
      <li>
        <select>
          <option><a className='a' href="#">Uzb</a></option>
          <option><a className='a' href="#">Rus</a></option>
        </select>
        </li>
      <li>
        <button className='Kirish'>
          <img className='person' src="https://cdn1.iconfinder.com/data/icons/mix-color-4/502/Untitled-1-512.png" alt="" />
          <select className='selekt'>
            <option className='selekkt'>
              <a className='a' href="#">Kirish</a>
              </option>
          </select>
        </button>
        </li>
    </ol>
    </div>
  }
}

export default Header;