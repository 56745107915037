import React, { Component } from 'react'

export default class Biz extends Component {
  render() {
    return (
        <div className='biz'>
        <p className='pro'>Prof<span>.uz</span></p>
        <p className='xalq'>Xalq Ta'limi Vazirligi Copyright 2021 (c) Multimedia markazi
email: prof@itsm.uz</p>
       <div className='flex'>
       <nav className='nav'>
         <ol>
           <li className='l'><a className='b' href="in.js">Biz haqimizda</a></li>
           <li className='l'><a className='b' href="#">Yordam</a></li>
           <li className='l'><a className='b' href="#">Saytlarimiz</a></li>
           <li className='l'><a className='b' href="#">Bog'lanish</a></li>
         </ol>
       </nav>
       <nav className='navq'>
         <ol>
           <li className='l'><a className='b' href="#">Ro'yxatdan o'tish</a></li>
           <li className='l'><a className='b' href="#">Tizimga kirish</a></li>
           <li className='l'><a className='b' href="#">Kasp tanlash</a></li>
           <li className='l'><a className='b' href="#">Treninglar</a></li>
         </ol>
       </nav>
       <nav className='nav'>
         <ol>
           <li className='l'><a className='b' href="#">xtv.uz</a></li>
           <li className='l'><a className='b' href="#">kitob.uz</a></li>
           <li className='l'><a className='b' href="#">ziyouz.com</a></li>
           <li className='l'><a className='b' href="#">ziyonet.uz</a></li>
         </ol>
       </nav>
       <nav className='nav'>
         <ol>
           <li className='l1'>
             <a className='b1' href="https://www.youtube.com/">/youtube</a></li>
           <li className='l1'>
             <a className='b1' href="https://www.facebook.com/">/facebook</a></li>
           <li className='l1'>
             <a className='b1 '  href="https://www.instagram.com/">/instagram</a></li>
         </ol>
       </nav>
       <div className='instagram'>
       <div>
       <img className='http' src="https://i.pinimg.com/originals/6a/42/04/6a4204f04496559aa27101d25983d0f0.png" alt="" />
       </div>
       <div>
       <img className='http' src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/05/Facebook_Logo_%282019%29.png/768px-Facebook_Logo_%282019%29.png" alt="" />
       </div>
       <div>
       <img className='http' src="https://www.pngfind.com/pngs/m/125-1255478_logo-instagram-rosa-png-instagram-icon-png-pink.png" alt="" />
       </div>
       </div>
       </div>
        </div>
    )
  }
}
